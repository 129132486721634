import { UsrclkPrefix } from '../../../types';

interface Props {
  title: string;
  href: string;
  isExternal?: boolean;
}

interface ShareContentsProps {
  href: string;
  image: string;
  alt: string;
}

/**
 * ナビゲーション右上コンテンツ
 * @param prefix 接頭辞（ログイン状態）
 * @returns ナビゲーション右上コンテンツの詳細
 */
export const getRules = (prefix: UsrclkPrefix): Props[] => [
  {
    title: '利用規約',
    href: `/material/kiyaku/001.html?usrclk=${prefix}commonfooter_terms_kiyaku-001`,
  },
  {
    title: '取扱職種の範囲等',
    href: 'https://www.persol-career.co.jp/jobtypes/',
  },
  {
    title: '利用者データの外部送信',
    href: 'https://www.persol-career.co.jp/terms/cookie/',
    isExternal: true,
  },
  {
    title: '免責事項',
    href: `/info/menseki/?usrclk=${prefix}commonfooter_terms_menseki`,
  },
  {
    title: 'サイトマップ',
    href: `/sitemap.html?usrclk=${prefix}commonfooter_terms_sitemap`,
  },
  {
    title: 'ヘルプ',
    href: `https://faq.doda.jp/?usrclk=${prefix}commonfooter_terms_help`,
  },
];

/**
 * ナビゲーションリンクリスト_doda転職サービス
 * @param prefix 接頭辞（ログイン状態）
 * @returns ナビゲーションリンクリスト_doda転職サービスの詳細
 */
export const getServices = (prefix: UsrclkPrefix): Props[] => [
  {
    title: 'はじめての方へ',
    href: `/intro/?usrclk=${prefix}commonfooter_service_intro`,
  },
  {
    title: '求人情報検索',
    href: `/DodaFront/View/JobSearchTop/j_from_global_navi__True/?usrclk=${prefix}commonfooter_service_jobSearchList`,
  },
  {
    title: 'エージェントサービス',
    href: `/consultant/?usrclk=${prefix}commonfooter_service_agent`,
  },
  {
    title: 'スカウトサービス',
    href: `/scout/?usrclk=${prefix}commonfooter_service_scout`,
  },
];

/**
 * ナビゲーションリンクリスト_専門サイト
 * @param prefix 接頭辞（ログイン状態）
 * @returns ナビゲーションリンクリスト_専門サイトの詳細
 */
export const getSpecialties = (prefix: UsrclkPrefix): Props[] => [
  {
    title: 'ITエンジニア',
    href: `/engineer/?usrclk=${prefix}commonfooter_specialSite_engineer`,
  },
  {
    title: 'モノづくりエンジニア',
    href: `/engineer/mono/?usrclk=${prefix}commonfooter_specialSite_engineer-mono`,
  },
  {
    title: 'メディカル',
    href: `/medical/?usrclk=${prefix}commonfooter_specialSite_medical`,
  },
  {
    title: 'セールス',
    href: `/sales/?usrclk=${prefix}commonfooter_specialSite_sales`,
  },
  {
    title: 'ファイナンス',
    href: `/finance/?usrclk=${prefix}commonfooter_specialSite_finance`,
  },
  {
    title: 'スペシャリスト',
    href: `/specialist/?usrclk=${prefix}commonfooter_specialSite_specialist`,
  },
  {
    title: 'エグゼクティブ',
    href: `/executive/?usrclk=${prefix}commonfooter_specialSite_executive`,
  },
  {
    title: 'グローバル（アジア）',
    href: `/global/?usrclk=${prefix}commonfooter_specialSite_global`,
  },
  {
    title: 'Woman Career',
    href: `/woman/?usrclk=${prefix}commonfooter_specialSite_woman`,
  },
];

/**
 * ナビゲーションリンクリスト_地域別サイト
 * @param prefix 接頭辞（ログイン状態）
 * @returns ナビゲーションリンクリスト_地域別サイトの詳細
 */
export const getAreas = (prefix: UsrclkPrefix): Props[] => [
  {
    title: '北海道',
    href: `/area/hokkaido/?usrclk=${prefix}commonfooter_location_hokkaidoArea`,
  },
  {
    title: '東北',
    href: `/area/tohoku/?usrclk=${prefix}commonfooter_location_touhokuArea`,
  },
  {
    title: '関東',
    href: `/area/kanto/?usrclk=${prefix}commonfooter_location_kantouArea`,
  },
  {
    title: '東海',
    href: `/area/tokai/?usrclk=${prefix}commonfooter_location_toukaiArea`,
  },
  {
    title: '北信越',
    href: `/area/hokushin/?usrclk=${prefix}commonfooter_location_hokushinetsuArea`,
  },
  {
    title: '関西',
    href: `/area/kansai/?usrclk=${prefix}commonfooter_location_kansaiArea`,
  },
  {
    title: '中国・四国',
    href: `/area/chugokushikoku/?usrclk=${prefix}commonfooter_location_chugokushikokuArea`,
  },
  {
    title: '九州・沖縄',
    href: `/area/kyushu/?usrclk=${prefix}commonfooter_location_kyushuokinawaArea`,
  },
  {
    title: '海外',
    href: `/area/kaigai/?usrclk=${prefix}commonfooter_location_kaigaiArea`,
  },
];

/**
 * ナビゲーションリンクリスト_転職イベント・セミナー
 * @param prefix 接頭辞（ログイン状態）
 * @returns ナビゲーションリンクリスト_転職イベント・セミナーの詳細
 */
export const getEvents = (prefix: UsrclkPrefix): Props[] => [
  {
    title: '転職イベント・セミナー情報',
    href: `/event/?usrclk=${prefix}commonfooter_event_event`,
  },
  {
    title: 'doda転職フェア',
    // TODO: クリック時にログインのprefixを外すか確認中
    href: `/e/fair/?usrclk=${prefix}commonfooter_event_fair`,
  },
  {
    title: '転職セミナー動画',
    href: `/event/webmovie/?usrclk=${prefix}commonfooter_event_webmovie`,
  },
];

/**
 * ナビゲーションリンクリスト_関連情報
 * @param prefix 接頭辞（ログイン状態）
 * @returns ナビゲーションリンクリスト_関連情報の詳細
 */
export const getRelatedInformations = (prefix: UsrclkPrefix): Props[] => [
  {
    title: '転職活動の進め方',
    href: `/guide/junbi/?usrclk=${prefix}commonfooter_contents_junbi`,
  },
  {
    title: '履歴書の書き方',
    href: `/guide/rireki/?usrclk=${prefix}commonfooter_contents_rireki`,
  },
  {
    title: '職務経歴書の書き方',
    href: `/guide/syokureki/?usrclk=${prefix}commonfooter_contents_syokureki`,
  },
  {
    title: '志望動機の書き方',
    href: `/guide/rireki/douki/`,
  },
  {
    title: '自己PRの書き方',
    href: `/guide/rireki/jikopr/`,
  },
  {
    title: '求人応募の仕方',
    href: `/guide/oubo/?usrclk=${prefix}commonfooter_contents_oubo`,
  },
  {
    title: '面接の流れと質問',
    href: `/guide/mensetsu/?usrclk=${prefix}commonfooter_contents_mensetsu`,
  },
  {
    title: '内定・退職・入社の手続き',
    href: `/guide/naiteitaisyoku/?usrclk=${prefix}commonfooter_contents_naiteitaisyoku`,
  },
  {
    title: '第二新卒の転職活動',
    href: `/guide/nisotsu/?usrclk=${prefix}commonfooter_contents_nisotsu`,
  },
  {
    title: '人気企業ランキング',
    href: `/guide/popular/?usrclk=${prefix}commonfooter_contents_popular`,
  },
  {
    title: '平均年収',
    href: `/guide/heikin/?usrclk=${prefix}commonfooter_contents_heikin`,
  },
  {
    title: '生涯年収',
    href: `/guide/heikin/lifetime_earnings/?usrclk=${prefix}commonfooter_contents_lifetime-earnings`,
  },
  {
    title: '転職理由',
    href: `/guide/reason/?usrclk=${prefix}commonfooter_contents_reason`,
  },
  {
    title: '転職年齢',
    href: `/guide/age/?usrclk=${prefix}commonfooter_contents_age`,
  },
  {
    title: '中途採用の実態',
    href: `/guide/saiyo/?usrclk=${prefix}commonfooter_contents_saiyo`,
  },
  {
    title: '仕事満足度',
    href: `/guide/manzokudo/?usrclk=${prefix}commonfooter_contents_manzokudo`,
  },
  {
    title: '転職市場予測',
    href: `/guide/market/?usrclk=${prefix}commonfooter_contents_market`,
  },
  {
    title: 'ホンネの転職白書',
    href: `/guide/ranking/?usrclk=${prefix}commonfooter_contents_ranking`,
  },
  {
    title: '職種図鑑',
    href: `/guide/zukan/?usrclk=${prefix}commonfooter_contents_zukan`,
  },
  {
    title: '企業情報',
    href: `/companyinfo/?usrclk=${prefix}commonfooter_contents_companyTop`,
  },
];

/**
 * 外部サイトリスト
 * @param prefix
 * @returns 外部サイトリストの詳細
 */
export const getExternalSites = (prefix: UsrclkPrefix): Props[] => [
  {
    href: `https://www.persol-career.co.jp/?usrclk=${prefix}commonfooter_corporate_top`,
    title: '会社案内',
    isExternal: true,
  },
  {
    href: `https://www.persol-career.co.jp/corporate/office/?usrclk=${prefix}commonfooter_corporate_map`,
    title: '拠点一覧',
    isExternal: true,
  },
  {
    href: `https://www.saiyo-doda.jp/lp/tm/main/?usrclk=${prefix}commonfooter_corporate_lp&utm_medium=referral&utm_source=doda.jp&utm_campaign=doda.jp_topfooter&cid=referral_dodajp_topfooter_00_00_00`,
    title: '求人掲載・採用企業の方へ',
    isExternal: true,
  },
];

/**
 * 関連サイト
 */
export const RELATED_SITE = [
  {
    title: '転職',
    href: '/',
  },
  {
    title: 'エンジニア転職',
    href: '/engineer/',
  },
  {
    title: '障害者転職',
    href: '/challenge/',
  },
  {
    title: 'エグゼクティブ転職',
    href: '/executive/',
  },
  {
    title: 'アパレル求人',
    href: 'https://www.crede.co.jp/',
    isExternal: true,
  },
  {
    title: '適職・転職フェア',
    href: '/e/fair/',
    isExternal: true,
  },
  {
    title: '採用・求人広告',
    href: 'https://saiyo-doda.jp/',
    isExternal: true,
  },
];

// ナビゲーションSNSコンテンツ
export const SHARE_CONTENTS: ShareContentsProps[] = [
  {
    href: `https://twitter.com/doda_persol`,
    image: 'https://doda.jp/cmn_web/img/common/btn_foot_001.png',
    alt: 'エックス',
  },
  {
    href: `https://www.facebook.com/doda.jp`,
    image: 'https://doda.jp/cmn_web/img/common/btn_foot_002.png',
    alt: 'フェイスブック',
  },
  {
    href: `http://b.hatena.ne.jp/entrylist?url=https%3A%2F%2Fdoda.jp%2F`,
    image: 'https://doda.jp/cmn_web/img/common/btn_foot_003.png',
    alt: 'はてなブックマーク',
  },
];

/**
 * グループ会社一覧
 */
export const GROUP_COMPANIES = [
  {
    title: 'パーソルホールディングス',
    href: 'https://www.persol-group.co.jp/',
  },
  {
    title: 'パーソルテンプスタッフ',
    href: 'https://www.tempstaff.co.jp/corporate/',
  },
  {
    title: 'パーソルクロステクノロジー',
    href: 'https://persol-xtech.co.jp/',
  },
  {
    title: 'パーソルキャリア',
    href: 'https://www.persol-career.co.jp/',
  },
  {
    title: 'パーソルイノベーション',
    href: 'https://persol-innovation.co.jp/',
  },
  {
    title: 'パーソル総合研究所',
    href: 'https://rc.persol-group.co.jp/',
  },
];

/**
 * 個人向けサービス
 */
export const PERSONAL_SERVICES = [
  {
    title: '人材派遣',
    links: [
      {
        title: 'テンプスタッフ',
        href: 'https://www.tempstaff.co.jp/',
      },
      {
        title: 'ジョブチェキ',
        href: 'https://www.tempstaff.co.jp/jbch/top',
      },
      {
        title: 'ファンタブル',
        href: 'https://www.tempstaff.co.jp/kmenu/s010/',
      },
      {
        title: 'フレキシブルキャリア',
        href: 'https://www.tempstaff.co.jp/kmenu/s047/',
      },
      {
        title: 'Chall-edge',
        href: 'https://www.tempstaff.co.jp/kmenu52/',
      },
      {
        title: 'パーソルクロステクノロジー',
        href: 'https://staff.persol-xtech.co.jp/',
      },
    ],
  },
  {
    title: '転職・就職',
    links: [
      {
        title: 'doda',
        href: 'https://doda.jp/',
      },
      {
        title: 'エグゼクティブエージェント',
        href: 'https://executive-agt.jp/',
      },
      {
        title: 'BRS',
        href: 'https://brs-p.jp/',
      },
      {
        title: 'クリーデンス',
        href: 'https://www.crede.co.jp/',
      },
      {
        title: 'ミイダス',
        href: 'https://miidas.jp/',
      },
      {
        title: 'dodaチャレンジ',
        href: 'https://doda.jp/challenge/',
      },
      {
        title: 'doda X',
        href: 'https://doda-x.jp/',
      },
      {
        title: 'U_29',
        href: 'https://staff.persol-xtech.co.jp/u_29/',
      },
    ],
  },
  {
    title: 'その他',
    links: [
      {
        title: 'lotsful',
        href: 'https://lotsful.jp/',
      },
      {
        title: 'シェアフル',
        href: 'https://sharefull.com/',
      },
      {
        title: 'ミラトレ',
        href: 'https://mirai-training.jp/',
      },
      {
        title: 'Neuro Dive',
        href: 'https://neuro-diversity.biz/',
      },
      {
        title: 'HiPro',
        href: 'https://hipro-job.jp/',
      },
    ],
  },
];

/**
 * 法人向けサービス
 */
export const CORPORATE_SERVICES = [
  {
    title: 'その他',
    links: [
      {
        title: 'doda（採用・求人広告）',
        href: 'https://www.saiyo-doda.jp/lp/tm/main/?utm_medium=referral&utm_source=doda.jp&utm_campaign=doda.jp_topfooter&cid=referral_dodajp_topfooter_00_00_00',
      },
      {
        title: 'パーソルのRPA',
        href: 'https://www.persol-wsc.co.jp/persolrpa/',
      },
      {
        title: 'ワークスイッチコンサルティング',
        href: 'https://www.persol-wsc.co.jp/',
      },
      {
        title: 'HITO-Manager',
        href: 'https://hitomgr.jp/lp/',
      },
      {
        title: 'MITERAS',
        href: 'https://www.persol-pt.co.jp/miteras/',
      },
      {
        title: 'ポスタス',
        href: 'https://www.postas.co.jp/',
      },
      {
        title: 'Reskilling Camp',
        href: 'https://www.reskillingcamp.jp/',
      },
    ],
  },
];

/**
 * フッター_個人情報の取り扱いについて
 * @param prefix 接頭辞（ログイン状態）
 * @returns 個人情報の取り扱いについてのURL
 */
export const getPersonalInformation = (prefix: UsrclkPrefix) => {
  return `https://www.persol-career.co.jp/privacy/detail/?usrclk=${prefix}bottom_personalInformation`;
};
